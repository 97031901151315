import React from 'react'
var QRCode = require('qrcode.react');

require('dotenv').config()

class GuestCard extends  React.Component {
    constructor( props ) {
        super( props )
        this.state = {
        }
    }

    render() {
        let { guest } = this.props
        return (
            <>
                <li className="list-group-item QrCode">
                    <div className="card mb-3">
                        <div className="row g-0">
                            <div className="col-md-2">
                                <div className="card-body">
                                    <h5 className="card-title">{guest.lastname} / {guest.firstname}</h5>
                                    <p className="card-text"><a href={`${process.env.REACT_APP_URL}/auth/${guest.api_token}`} className="btn btn-primary">Lien du qrcode</a></p>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <QRCode value={`${process.env.REACT_APP_URL}/auth/${guest.api_token}`} size="512" includeMargin={true} />
                            </div>
                        </div>
                    </div>
                </li>
            </>
        )
    }
}

export default GuestCard;