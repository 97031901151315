import React from 'react'
import Axios from 'axios'
import { Redirect, Link } from 'react-router-dom'
import PhotoAdd from '../../assets/img/PhotoAdd.svg';

import PhotoCard from './PhotoCard.js'

require('dotenv').config()

class Photos extends  React.Component {
    constructor( ) {
        super()
        this.state = {
            photos: [],
            redirect: false,
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                } 
            }
            Axios.get( process.env.REACT_APP_API_URL + '/api/photos', headers )
            .then( res => {
                this.setState({ photos: res.data.success })
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirect: true })
                } else if ( errors.response.status === 500 ) {
                    this.setState({ errors: errors.response.errors })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirect: true })
        }
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/noauth" />)
        }
        return (
            <>
                <div className="container">
                    <div className="row justify-content-start Photos">
                        <div className="PhotosHeader"><p>Galerie de la Blonde & du Barbu.</p></div>
                        <div className="PhotosCount"><p>{this.state.photos.length} photo{ this.state.photos.length > 0 ? 's' : '' }</p></div>
                        { 
                            this.state.photos && this.state.photos.length > 0 && this.state.photos.map( (photo) => 
                                <PhotoCard photo={photo} />
                            )
                        }
                    </div>
                    <div className="PhotoAdd">
                        <Link to="/camera/new" ><img src={PhotoAdd} alt="Prendre une photo" /></Link>
                    </div>
                </div>
            </>
        )
    }
}

export default Photos
