import React from 'react'
import ReactLoading from 'react-loading';
import Axios from 'axios'
import { Redirect } from 'react-router-dom'

require('dotenv').config()

class Home extends  React.Component {
    constructor( ) {
        super()
        this.state = {
            redirectNoAuth: false,
            redirectAuth: false,
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                } 
            }
            Axios.get( process.env.REACT_APP_API_URL + '/api/guest/token', headers )
            .then( res => {
                if ( res.data.success === true )
                this.setState({ redirectAuth: true })
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirect: true })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirectNoAuth: true })
        }
    }
    
    render () {
        if ( this.state.redirectNoAuth ) {
            return ( <Redirect to="/noauth" />)
        }
        if ( this.state.redirectAuth ) {
            return ( <Redirect to="/camera/new" />)
        }
        return (
            <>
                <div className="container">
                    <div className="Home">
                        <ReactLoading className="Loading" type="balls" color="#FFFFFF" height={'15%'} width={'15%'} />
                    </div>
                </div>
            </>
        )
    }
}

export default Home