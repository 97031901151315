import React from 'react'
import Axios from 'axios'
import GuestCard from './GuestCard.js'

require('dotenv').config()

class ListQRcode extends  React.Component {
    constructor( ) {
        super()
        this.state = {
            guests: [],
        }
    }

    componentDidMount() {
        Axios.get( process.env.REACT_APP_API_URL + '/api/guests' )
        .then( res => {
            this.setState({ guests: res.data.success })
        })
        .catch( errors => {
            if ( errors.response && errors.response.status !== 200 ) {
                localStorage.setItem( 'api_token', '' )
                localStorage.clear()
                this.setState({ redirect: true })
            } else {
                console.warn( errors.response )
            }
        })
    }

    render () {
        return (
            <>
                <div className="container">
                    <div className="row justify-content-start Guests">
                        <ul className="list-group">
                            { 
                                this.state.guests.map( (guest) => 
                                    <GuestCard guest={guest} />
                                )
                            }
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default ListQRcode
