import React from 'react'
import Axios from 'axios'
import { Redirect } from 'react-router-dom'

require('dotenv').config()

class Auth extends React.Component {

    constructor( props ) {
        super( props )
        this.state = {
            redirect: false,
            isLogged: false,
        }
    }

    componentDidMount() {
        console.log( this.props.match.params )
        let api_token = this.props.match.params.token

        if ( ! api_token ) {
            this.setState({ redirect: true })
        } else {
            let headers = {
                headers: {
                    'API-TOKEN' : api_token
                } 
            }
            Axios.get( process.env.REACT_APP_API_URL + '/api/guest/token', headers )
            .then( res => {
                if ( res.data.success === true ) {
                    localStorage.setItem( 'api_token', api_token )
                    this.setState({ isLogged: true })
                }
            })
            .catch( errors => {
                if ( errors.response && errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirect: true })
                } else {
                    console.warn( errors.response )
                }
            })
        }
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/noauth" />)
        }
        if ( this.state.isLogged ) {
            return ( <Redirect to="/camera/new" />)
        }
        return (
            <>
            </>
        )
    }

}

export default Auth