import React from 'react'
import Axios from 'axios'
import { Redirect, Link } from 'react-router-dom'

import Close from '../../assets/img/Close.svg'

require('dotenv').config()

// import Header from '../navigation/Header.js'

class PhotoAdd extends  React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            photo: '',
            errors: [],
            redirectNoAuth: false,
            redirectPhotos: false,
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                } 
            }
            Axios.get( process.env.REACT_APP_API_URL + '/api/guest/token', headers )
            .then( res => {
                if ( res.data.success === true )
                this.setState({ redirectAuth: true })
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirectNoAuth: true })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirectNoAuth: true })
        }
    }

    handleChanePhoto = (event) =>  {
        this.setState({ photo : event.target.files[0] }, () => { console.log( this.state.photo ) })
        this.setState({ errors: [] }, () => { console.warn( this.state.errors )})
    } 

    handleSubmit = (event) => {
        event.preventDefault()
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                } 
            }
            let bodyFormData = new FormData();
            bodyFormData.set( 'defi_id' , 1)
            bodyFormData.set( 'photo' , this.state.photo )

            console.log( this.state.photo  );
            Axios.post( process.env.REACT_APP_API_URL + '/api/photo', bodyFormData, headers )
            .then(res => {
                if ( res.data.success ) {
                    this.setState({ redirectPhotos: true })
                }
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirectNoAuth: true })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirect: true })
        }
    }

    render () {
        if ( this.state.redirectNoAuth ) {
            return ( <Redirect to="/noauth" />)
        }
        if ( this.state.redirectPhotos ) {
            return ( <Redirect to="/photos" />)
        }
        return (
            <>
                <Link to="/photos"><img src={Close} alt="Defi choisi" className="PhotoCameraClose" /></Link>
                <div className="container">
                    <p className="PhotoUploadTitle">Ajouter une photo à partager</p>
                    <div className="PhotoUploadForm">
                        <form method="POST" onSubmit={this.handleSubmit} className="Forms text-start" encType="multipart/form-data">
                            <div className="mb-3">
                                <label htmlFor="PhotoUploadFormLabel" className="form-label mb-3">Fait ton choix ;)</label>
                                <input type="file" className={`form-control mb-3 ${ this.state.errors && this.state.errors.photo  ? 'is-invalid' : ''}`} id="exampleInputPhoto" onChange={this.handleChanePhoto} />
                                { this.state.errors && this.state.errors.photo ? <div className="text-danger invalid-feedback" >{ this.state.errors.photo }</div> : '' }
                            </div>
                            { this.state.errors && this.state.errors.code === 'bad_credentials' ? <div className="alert alert-danger" >{ this.state.errors.msg }</div> : '' }
                            <button type="submit" className="btn btn-primary">AZY je la partage !!!</button>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default PhotoAdd
