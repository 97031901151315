
import AppRouter from './AppRouter'
import { BrowserRouter as Router } from 'react-router-dom'

import "./index.scss"

function App() {
  return (
    <Router>
      <AppRouter/>
    </Router>
  );
}

export default App;
