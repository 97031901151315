import React from 'react'
import Axios from 'axios'
import { Redirect, Link } from 'react-router-dom'
import ReactLoading from 'react-loading';
import { uuid } from 'uuidv4';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import Close from '../../assets/img/Close.svg';
import Upload from '../../assets/img/Upload.svg';
import ChangeView from '../../assets/img/ChangeView.svg';

import Defi from '../partials/Defi';
import Defis from '../partials/Defis';

require('dotenv').config()

// import Header from '../navigation/Header.js'

class CameraAdd extends  React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            mode: FACING_MODES.ENVIRONMENT,
            photo: '',
            defiChoice: 1,
            errorMsg: '',
            loading: true,
            showDefis: true,
            showPreview: false,
            redirectNoAuth: false,
            redirectPhotos: false,
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                } 
            }
            Axios.get( process.env.REACT_APP_API_URL + '/api/guest/token', headers )
            .then( res => {
                if ( res.data.success === true ) {
                    this.setState({ loading: false })
                }
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirectNoAuth: true })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirectNoAuth: true })
        }
    }

    handleTakePhoto = (dataUri) => {
        this.setState({ loading: false, showPreview : true, photo: dataUri })
    }

    dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
            u8arr[n - 1] = bstr.charCodeAt(n - 1)
            n -= 1 // to make eslint happy
        }
        return new File([dataurl], filename, { type: mime })
    }

    changeCamera = () => {
        if ( this.state.mode === FACING_MODES.USER ) {
            this.setState({mode: FACING_MODES.ENVIRONMENT})
        } else {
            this.setState({mode: FACING_MODES.USER})
        }
    }

    photoUpload = () => {
        if ( this.state.mode === FACING_MODES.USER ) {
            this.setState({mode: FACING_MODES.ENVIRONMENT})
        } else {
            this.setState({mode: FACING_MODES.USER})
        }
    }

    valideChoice = (defiChoice) => {
        this.setState({ showDefis: false, defiChoice: defiChoice })
    }

    keepIt = () => {
        this.okLetsGo()
    }

    tryAgain = () => {
        this.setState({ photo : '', showPreview : false })
    }

    okLetsGo = () => {
        this.setState({ loading: true })
        if ( localStorage.getItem( 'api_token' ) ) {
            fetch( this.state.photo )
            .then(res => res.blob())
            .then(blob => {
                let headers = {
                    headers: {
                        'API-TOKEN' : localStorage.getItem( 'api_token' ),
                        'Content-Type': 'multipart/form-data',
                    } 
                }
                let bodyFormData = new FormData();
                
                const screenShoot = new File([blob], uuid() + ".png",{ type: "image/png" })
                console.log(screenShoot);
                bodyFormData.set( 'defi_id' , parseInt( this.state.defiChoice ) )
                bodyFormData.set( 'photo' , screenShoot )
    
                Axios.post( process.env.REACT_APP_API_URL + '/api/photo', bodyFormData, headers )
                .then(res => {
                    if ( res.data.success ) {
                        this.setState({ redirectPhotos: true })
                    }
                })
                .catch( errors => {
                    if ( errors.response.status === 403 ) {
                        localStorage.setItem( 'api_token', '' )
                        localStorage.clear()
                        this.setState({ redirectNoAuth: true })
                    } else if ( errors.response.status === 500 ) {
                        this.setState({ errorMsg: errors.response.data.message })
                    } else {
                        console.warn( errors.response )
                    }
                })
            })
        } else {
            this.setState({ redirectNoAuth: true })
        }
    }


    refreshPage = () => {
        window.location.reload();
    }

    render () {
        console.log(this.state.defis)
        if ( this.state.redirectNoAuth ) {
            return ( <Redirect to="/noauth" />)
        }
        if ( this.state.redirectPhotos ) {
            return ( <Redirect to="/photos" />)
        }

        if ( this.state.showDefis ) {
            return ( this.state.showDefis ? <Defis okLetsGo={this.okLetsGo} valideChoice={this.valideChoice} /> : '' )
        }
        if ( this.state.showPreview ) {
            return (
                <>
                    {
                        ( String(this.state.errorMsg).length > 0 ? <div className="PhotoAddError alert alert-danger" ><p>{this.state.errorMsg}</p><button onClick={ this.refreshPage } type="button" class="btn btn-danger">Recharge cette page</button></div> : '' )
                    }
                    <div className="PhotoPreview">
                        <div className="PhotoPreviewImg">
                            <img src={this.state.photo} alt="" />
                        </div>
                        <div className="PhotoPreviewControls">
                            {
                                this.state.loading ? 
                                    <ReactLoading className="PhotoLoading" type={'spinningBubbles'} color={'#FFFFFF'} height={'5%'} width={'5%'} />
                                :
                                    <>
                                        <button className="btn btn-primary" onClick={this.keepIt}>Elle est top !!! On la garde</button>
                                        <button className="btn btn-secondary" onClick={this.tryAgain}>Bof... je recommence</button>
                                    </>
                            }
                        </div>
                    </div>
                </>
            )
        }
        return (
            <>
                <Defi defi={this.state.defiChoice} />
                <Link to="/photos"><img src={Close} alt="Defi choisi" className="PhotoCameraClose" /></Link>
                <Link to="/photo/new"><span className="PhotoCameraUpload"><img src={Upload} alt="Upload un photo" /></span></Link>
                <Camera
                    onTakePhoto={this.handleTakePhoto}
                    isMaxResolution={true}
                    isFullscreen={true}
                    isImageMirror={false}
                    idealFacingMode={this.state.mode}
                    imageType={IMAGE_TYPES.PNG}
                />
                <span className="PhotoCameraChange"><img src={ChangeView} alt="Changer de caméra" onClick={this.changeCamera} /></span>
            </>
        )
    }
}

export default CameraAdd
