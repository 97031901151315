import React from 'react'

class NoAuth extends  React.Component {
    render () {
        return (
            <>
                <div className="container">
                    <div className="NoAuth">
                    </div>
                </div>
            </>
        )
    }
}

export default NoAuth