import React from 'react'
import Axios from 'axios'

require('dotenv').config()

class Defi extends  React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            defis: [],
            defiChoice: 1
        }
    }
    

    componentDidMount() {
        // function sleep (time) {
        //     return new Promise((resolve) => setTimeout(resolve, time));
        // }
        // sleep(2000).then(() => {
            if ( localStorage.getItem( 'api_token' ) ) {
                let headers = {
                    headers: {
                        'API-TOKEN' : localStorage.getItem( 'api_token' )
                    } 
                }
                Axios.get( process.env.REACT_APP_API_URL + '/api/defis', headers )
                .then( res => {
                    this.setState({ defis: res.data.success })
                })
                .catch( errors => {
                    if ( errors.response.status === 403 ) {
                        localStorage.setItem( 'api_token', '' )
                        localStorage.clear()
                        this.setState({ redirectNoAuth: true })
                    } else {
                        console.warn( errors.response )
                    }
                })
            } else {
                this.setState({ redirectNoAuth: true })
            }
        // })
    }

    render() {
        let { defis } = this.state
        console.log(this.props.defi)
        console.log(defis && defis.length > 0)
        return (
            <>
                { 
                    
                    defis && defis.length > 0  && defis.map( (defi,index) => 
                        {
                            return (
                                this.props.defi === defi.id && <p className="PhotoCameraDefiChoiceTitle">{defi.title}</p>
                            )
                        }
                    )
                }
            </>
        )
    }
}

export default Defi;