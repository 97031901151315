import React from 'react'

import NoAuth from './components/pages/NoAuth.js'
import Auth from './components/pages/Auth.js'
import Home from './components/pages/Home.js'
import Photos from './components/pages/Photos.js'
import PhotoAdd from './components/pages/PhotoAdd.js'
import CameraAdd from './components/pages/CameraAdd.js'
import ListQRcode from './components/pages/ListQRcode.js'

import { Switch, Route } from 'react-router-dom'

class AppRouter extends React.Component {
    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/noauth/" component={NoAuth} />
                    <Route exact path="/" component={Home} />
                    <Route exact path="/photos" component={Photos} />
                    <Route exact path="/photo/new" component={PhotoAdd} />
                    <Route exact path="/camera/new" component={CameraAdd} />
                    <Route exact path="/list/qrcode" component={ListQRcode} />
                    <Route path="/auth/:token" component={Auth} />
                </Switch>
            </>
        )
    }
}

export default AppRouter