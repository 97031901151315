import React from 'react'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

class PhotoCard extends  React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            fullscreen: false
        }
    }

    fullscreen = () => {
        this.setState({ fullscreen: !this.state.fullscreen })
    }

    render() {
        let { photo } = this.props
        return (
            <>
                <div className="card p-0 mx-2 mb-4" >
                    <p className="defi">{photo.defi.title}</p>
                    <img onClick={this.fullscreen}  src={`${process.env.REACT_APP_API_URL}/storage/photos/${photo.thumbnail}`} className={`card-img preview`} alt={photo.defi.title} loading="lazy" />
                </div>
                <div onClick={this.fullscreen} className={`${ (this.state.fullscreen ? 'fullscreen' : 'hide' ) }`}>
                    <CloseRoundedIcon onClick={this.changeCamera} className="PhotoCameraClose">add_circle</CloseRoundedIcon>
                    <div className="photo">
                        <div className="photo-content">
                            <p>{photo.defi.title}</p>
                            <img src={`${process.env.REACT_APP_API_URL}/storage/photos/${photo.photo}`}  alt={photo.defi.title} loading="lazy" />
                        </div> 
                    </div>    
                </div>
            </>
        )
    }
}

export default PhotoCard;