import React from 'react'
import Axios from 'axios'
import ReactLoading from 'react-loading';

import Logo from '../../assets/img/Blonde&Barbue.png';

import RadioButton from '../../assets/img/RadioButton.svg';
import RadioButtonChecked from '../../assets/img/RadioButtonChecked.svg';

require('dotenv').config()

class Defis extends  React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            defis: [],
            defiChoice: 1
        }
    }
    

    componentDidMount() {
        // function sleep (time) {
        //     return new Promise((resolve) => setTimeout(resolve, time));
        // }
        // sleep(2000).then(() => {
            if ( localStorage.getItem( 'api_token' ) ) {
                let headers = {
                    headers: {
                        'API-TOKEN' : localStorage.getItem( 'api_token' )
                    } 
                }
                Axios.get( process.env.REACT_APP_API_URL + '/api/defis', headers )
                .then( res => {
                    this.setState({ defis: res.data.success })
                })
                .catch( errors => {
                    if ( errors.response.status === 403 ) {
                        localStorage.setItem( 'api_token', '' )
                        localStorage.clear()
                        this.setState({ redirectNoAuth: true })
                    } else {
                        console.warn( errors.response )
                    }
                })
            } else {
                this.setState({ redirectNoAuth: true })
            }
        // })
    }

    changeChoice = (event) => {
        var yourChoice = event.target.dataset.defiChoice
        if ( yourChoice === undefined ) {
            yourChoice = event.target.parentElement.dataset.defiChoice
        }
        if ( yourChoice === undefined ) {
            yourChoice = event.target.parentElement.parentElement.dataset.defiChoice
        }
        if ( yourChoice === undefined ) {
            yourChoice = event.target.parentElement.parentElement.parentElement.dataset.defiChoice
        }
        this.setState({defiChoice: parseInt(yourChoice)})
    } 

    valideChoice = () => {
        this.props.valideChoice(this.state.defiChoice)
    } 

    render() {
        let { defis } = this.state
        if ( defis && defis.length > 0 ) {
            return (
                <>
                    <div className="DefisList">
                        <img className="DefisListLogo" src={Logo} alt="Blonde&Barbu" width="100%" height="auto"  />
                        <p className="DefisListIntro">Parce qu’on est des oufs!</p>
                        <h1 className="DefisListTitle" >Blonde & Barbu Challenge !</h1>
                        <p className="DefisListAction">Choisi ton defi</p>
                        <ul className="DefisListContent">
                            { 
                                defis.map( (defi,index) => 
                                    <li className={( (index+1) === this.state.defiChoice ? 'checked' : '' )}key={index} onClick={this.changeChoice} data-defi-choice={defi.id}>
                                        <p className="DefisListContentItem">
                                            {defi.title}
                                            <span className="radio" >{( (index+1) === this.state.defiChoice ? <img src={RadioButtonChecked} alt="Defi choisi" /> : <img src={RadioButton} alt="Defi non choisi" /> )}</span> 
                                        </p>
                                    </li>
                                )
                            }
                        </ul>
                        <button onClick={this.valideChoice} type="button" className="btn btn-primary DefisButton">Je relève ce défi !!!</button>
                    </div>
                </>
            )
        }

        if ( defis && defis.length === 0 ) {
            return (
                <ReactLoading className="PhotoLoading" type={'spinningBubbles'} color={'#FFFFFF'} height={'5%'} width={'5%'} />
            )
        }
    }
}

export default Defis;